import axios from "@/axios.js"

export default {
    AddSurgeryMarkup({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios.post("api/SurgeryMarkup/AddSurgeryMarkup", item)
                .then((response) => {
                    commit('ADD_ITEM', Object.assign(item, { ID: response.data.Data.ID }))
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    GetAllSurgeryMarkups({ commit }, search) {
      return new Promise((resolve, reject) => {
          axios.post("api/SurgeryMarkup/SearchSurgeryMarkups", search).then((response) => {
                  commit('SET_SurgeryMarkup', response.data.Data)
                  resolve(response)
              })
              .catch((error) => { reject(error) })
      })
  },

    UpdateSurgeryMarkup(context, item) {
        debugger
        return new Promise((resolve, reject) => {
            axios
                .post("api/SurgeryMarkup/UpdateSurgeryMarkup", item)
                .then(response => {

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },




    GetSurgeryMarkup({ commit }, itemid) {
        return new Promise((resolve, reject) => {
            axios.get("api/SurgeryMarkup/GetSurgeryMarkup?ID=" + itemid)
                .then((response) => {
                    debugger
                    //commit('UPDATE_SurgeryMarkup', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    GetAllMarkupServiceTypes({ commit }) {
        return new Promise((resolve, reject) => {
            axios.get("api/SurgeryMarkup/GetAllMarkupServiceTypes")
                .then((response) => {
                    commit('SET_MarkupServiceTypes', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },



    DeleteSurgeryMarkup({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios.delete("api/SurgeryMarkup/DeleteSurgeryMarkup?ID=" + item.ID)
                .then((response) => {
                    commit('REMOVE_ITEM', item.ID)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
}
